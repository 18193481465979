import { useEffect, useState } from "preact/hooks";
import ProductTile from "../../islands/ProductTile.tsx";
import { CrossIcon, DropDownIcon } from "../SVGs.tsx";

function SearchModalMobile(
    {
        handleOverlayClick,
        search,
        setSearch,
        searchedProducts,
        setShowNotification,
        setNotificationType,
        setMessage,
        handleShow,
        setShowSearch,
    }: any,
) {
    const [isKeyboardVisible, setKeyboardVisible] = useState(false);

    useEffect(() => {
        const handleKeyboardShow = () => {
            setKeyboardVisible(true);
        };

        const handleKeyboardHide = () => {
            setKeyboardVisible(false);
        };

        globalThis.addEventListener("resize", () => {
            if (globalThis.innerHeight < 600) {
                handleKeyboardShow();
            } else {
                handleKeyboardHide();
            }
        });

        return () => {
            globalThis.removeEventListener("resize", handleKeyboardHide);
        };
    }, []);

    return (
        <>
            <div
                className={`fixed inset-x-0 bottom-[58px] z-[9999]  ${
                    isKeyboardVisible ? "mb-20" : "mb-0"
                }`}
            >
                <div
                    style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 30px" }}
                    className="bg-[#EDEDEF] text-light-blue-900 p-4 rounded-md mx-auto w-full relative h-full min-h-[410px] rounded-tl-[50px] rounded-tr-[50px]"
                >
                    <div
                        class="absolute top-[4%] right-[10%] !text-black cursor-pointer z-10"
                        onClick={() => setShowSearch(false)}
                    >
                        <DropDownIcon />
                    </div>
                    <input
                        type="text"
                        className="w-full p-2 rounded-md border border-light-blue-300 focus:outline-none focus:ring-2 focus:ring-light-blue-500 mt-4 bg-gray-100"
                        placeholder="Sök..."
                        value={search}
                        onChange={(e: any) => setSearch(e.target.value)}
                        onInput={(e: any) => setSearch(e.target.value)}
                    />
                    <div className="px-2 max-h-[410px] overflow-x-hidden overflow-y-scroll">
                        <ProductTile
                            products={searchedProducts}
                            setShowNotification={setShowNotification}
                            setNotificationType={setNotificationType}
                            setMessage={setMessage}
                            handleShow={handleShow}
                            isSearch={true}
                            search={search}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default SearchModalMobile;

