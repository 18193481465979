import { useEffect, useState } from "preact/hooks";
import POP from "./POP.tsx";
import Notification from "../components/ToastNotifications/index.tsx";
import axiod from "https://deno.land/x/axiod@0.26.2/mod.ts";
import { DataTableSchema } from "../database/products/schema.ts";
import SearchModal from "../components/SearchModal.tsx";
import { DropDownIcon, HamburgerIcon } from "../components/SVGs.tsx";
import BottomNav from "../components/Mobile/BottomNav.tsx";
import SearchModalMobile from "../components/Mobile/SearchModalMobile.tsx";
import MobileNav from "../components/Mobile/MobileNav.tsx";

const Header = ({ route }: any) => {
  const [show, setShow] = useState(false);
  const [cartItemsCount, setCartItemsCount] = useState(0);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [message, setMessage] = useState("");
  const [cat, setCat] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [searchedProducts, setSearchedProducts] = useState<DataTableSchema[]>(
    [],
  );

  const toggleSearch = () => {
    setShowSearch(!showSearch);
    setSearch("");
  };

  const getSearchedData = async () => {
    try {
      const response = await axiod.get("/api/search/products", {
        params: {
          search: search,
        },
      });
      if (response.status === 200) {
        setSearchedProducts(response.data.products);
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (search.length >= 3) {
      getSearchedData();
    } else {
      setSearchedProducts([]);
    }
  }, [search]);

  const handleOverlayClick = (e: any) => {
    if (e.target.id === "overlay") {
      setShowSearch(false);
    }
  };

  const closeNotification = () => {
    setShowNotification(false);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getCat = async () => {
    try {
      const response = await axiod.get("/api/categories");
      setCat(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCat();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const cartItems = JSON.parse(localStorage.getItem("cart-1") || "[]");
      const totalQuantity = cartItems.reduce(
        (total: any, item: any) => total + item.quantity,
        0,
      );
      setCartItemsCount(totalQuantity);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const matchRoute = (currentRoute: string) => {
    if (route === currentRoute) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Notification
        showNotification={showNotification}
        closeNotification={closeNotification}
        notificationType={notificationType}
        message={message}
      />
      <header>
        {/* Mobile */}
        <MobileNav
          toggleMenu={toggleMenu}
          toggleSearch={toggleSearch}
          isMenuOpen={isMenuOpen}
          showSearch={showSearch}
          matchRoute={matchRoute}
          handleShow={handleShow}
          cartItemsCount={cartItemsCount}
          cat={cat}
        />
        {/* Mobile END */}
        <div class="sticky-nav">
          <div className="container mx-auto px-4 header-mob">
            <div className="flex justify-between items-center p-4 header-mob-1 header-hd">
              <div className={`navp`}>
                <a href="/">
                  <img
                    src="/logo.png"
                    alt="logo"
                    className="w-full h-14 logo-mob"
                  />
                </a>
              </div>

              <div class="flex cart-mob">
                <div
                  className={`pr-2 search-ic ${
                    isMenuOpen ? "!hidden" : ""
                  } block lg:hidden search-mob`}
                  onClick={toggleSearch}
                >
                  <img
                    src="/search.png"
                    alt="search"
                    className="w-5 h-5 cursor-pointer"
                  />
                </div>
                <li
                  className={`cart-ic block lg:hidden ml-2`}
                >
                  <img
                    src="/cart.png"
                    alt="cart"
                    className="w-5 h-5"
                    onClick={handleShow}
                  />
                  <span className="count-mob">{cartItemsCount || 0}</span>
                </li>
              </div>

              {/* Navigation menu */}
              <div
                className={`navp hide-list ${
                  isMenuOpen ? "mx-auto mt-4" : "hidden"
                } lg:block`}
              >
                <ul
                  className={` ${
                    isMenuOpen ? "nav-bar flex flex-col" : "nav-bar flex !pr-0"
                  }`}
                >
                  <li>
                    <a href="/">HEM</a>
                  </li>
                  <li>
                    <a href="/info">INFO</a>
                  </li>
                  {cat &&
                    cat.map((c: any) => (
                      <li key={c.name}>
                        <a className="uppercase" href={`/${c.name}`}>
                          {c.name}
                        </a>
                      </li>
                    ))}
                  <li
                    className={`search-ic ${isMenuOpen ? "!hidden" : ""}`}
                    onClick={toggleSearch}
                  >
                    <img
                      src="/search.png"
                      alt="search"
                      className="w-5 h-5 cursor-pointer"
                    />
                  </li>
                  <li className={`cart-ic ${isMenuOpen ? "!hidden" : ""}`}>
                    <img
                      src="/cart.png"
                      alt="cart"
                      className="w-5 h-5"
                      onClick={handleShow}
                    />
                    <span className="count">{cartItemsCount || 0}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      {show && (
        <POP
          handleClose={handleClose}
          setNotificationType={setNotificationType}
          setShowNotification={setShowNotification}
          setMessage={setMessage}
        />
      )}
      <div class="desktop-search-modal">
        {showSearch && (
          <SearchModal
            showSearch={showSearch}
            handleOverlayClick={handleOverlayClick}
            search={search}
            setSearch={setSearch}
            searchedProducts={searchedProducts}
            setShowNotification={setShowNotification}
            setNotificationType={setNotificationType}
            setMessage={setMessage}
            handleShow={handleShow}
          />
        )}
      </div>
      <div class="mobile-search-modal">
        {showSearch && (
          <SearchModalMobile
            showSearch={showSearch}
            handleOverlayClick={handleOverlayClick}
            search={search}
            setSearch={setSearch}
            searchedProducts={searchedProducts}
            setShowNotification={setShowNotification}
            setNotificationType={setNotificationType}
            setMessage={setMessage}
            handleShow={handleShow}
            setShowSearch={setShowSearch}
          />
        )}
      </div>
    </>
  );
};

export default Header;

