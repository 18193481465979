import { HamburgerIcon, SearchIcon } from "../SVGs.tsx";

function BottomNav({ toggleMenu, toggleSearch, isMenuOpen, showSearch }: any) {
    function openMenu() {
        toggleMenu();
        if (showSearch) {
            toggleSearch();
        }
    }
    function openSearch() {
        toggleSearch();
        if (isMenuOpen) {
            toggleMenu();
        }
    }
    return (
        <div>
            <div class="flex h-[60px] justify-around border-t border-gray-200 bg-white shadow-lg">
                <div
                    class="flex flex-col items-center justify-center text-[#3F51B5] mt-1 gap-1"
                    onClick={openMenu}
                >
                    <HamburgerIcon />
                    <div class="text-sm font-medium">Meny</div>
                </div>
                <div
                    class="flex flex-col items-center justify-center text-[#3F51B5] mt-1 gap-1"
                    onClick={openSearch}
                >
                    <SearchIcon />
                    <div class="text-sm font-medium">Sök</div>
                </div>
            </div>
        </div>
    );
}

export default BottomNav;

