import { DropDownIcon } from "../SVGs.tsx";
import BottomNav from "./BottomNav.tsx";

function MobileNav(
    {
        toggleMenu,
        toggleSearch,
        isMenuOpen,
        showSearch,
        matchRoute,
        handleShow,
        cartItemsCount,
        cat
    }: any,
) {
    return (
        <>
            <div class="search-mob-small">
                <div class="fixed-bottom w-full">
                    <BottomNav
                        toggleMenu={toggleMenu}
                        toggleSearch={toggleSearch}
                        isMenuOpen={isMenuOpen}
                        showSearch={showSearch}
                    />
                </div>
                <div class="fixed-bottom bottom-[58px]">
                    {isMenuOpen && (
                        <div
                            class="absolute top-[4%] right-[10%] !text-black cursor-pointer z-10"
                            onClick={toggleMenu}
                        >
                            <DropDownIcon />
                        </div>
                    )}
                    <div
                        className={`navp  border-t-[1px] border-gray-300 ${
                            isMenuOpen ? "mx-auto" : "hidden"
                        } lg:block`}
                        style={{
                            background: "#EDEDEF", // #D5D6DB
                            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                            backdropFilter: "blur(40px)",
                            WebkitBackdropFilter: "blur(20px)",
                            border: "1px solid rgba(255, 255, 255, 0.3)",
                        }}
                    >
                        <ul
                            className={` ${
                                isMenuOpen
                                    ? "nav-bar flex flex-col"
                                    : "nav-bar flex !pr-0"
                            }`}
                        >
                            <li
                                class={`${
                                    matchRoute("/")
                                        ? "border-b border-gray-800 font-medium"
                                        : ""
                                }`}
                            >
                                <a class="" href="/">HEM</a>
                            </li>
                            <li
                                class={`${
                                    matchRoute("/info")
                                        ? "border-b border-gray-800 font-medium"
                                        : ""
                                }`}
                            >
                                <a href="/info">INFO</a>
                            </li>
                            {/* Dynamically render categories */}
                            {cat &&
                                cat.map((c: any) => (
                                    <li
                                        key={c.name}
                                        class={`${
                                            matchRoute(`/${c.name}`)
                                                ? "border-b border-gray-800 font-medium"
                                                : ""
                                        }`}
                                    >
                                        <a
                                            className="uppercase"
                                            href={`/${c.name}`}
                                        >
                                            {c.name}
                                        </a>
                                    </li>
                                ))}
                            <li
                                className={`search-ic ${
                                    isMenuOpen ? "!hidden" : ""
                                }`}
                                onClick={toggleSearch}
                            >
                                <img
                                    src="/search.png"
                                    alt="search"
                                    className="w-5 h-5 cursor-pointer"
                                />
                            </li>
                            <li
                                className={`cart-ic ${
                                    isMenuOpen ? "!hidden" : ""
                                }`}
                            >
                                <img
                                    src="/cart.png"
                                    alt="cart"
                                    className="w-5 h-5"
                                    onClick={handleShow}
                                />
                                <span className="count">
                                    {cartItemsCount || 0}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MobileNav;
